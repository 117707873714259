import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { handleAuthentication } from '../components/util/auth'
import { colors, fonts } from '../components/custom-button/styles'
import { navigate } from 'gatsby'
import CustomButton from '../components/custom-button/custom-button.component'
import { Link } from 'gatsby'
import { Navbar } from '../components/navbar'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import bgImage from '../images/employee-cover.svg'
import SEO from '../components/seo'

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	overflow: none;
`

const Message = styled.h1`
	color: ${colors.darkest};
	font-family: ${fonts.heading};
`

const VerifyEmailContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	a {
		color: #fff;
	}
`

export default ({ data }) => {
	React.useEffect(() => {
		if (window.location.hash.includes('error')) return
		handleAuthentication(({ state }) => navigate(state === '/' ? '/account' : state))
	})

	if (typeof window === 'undefined') {
		return null
	}

	if (window.location.hash.includes('error')) {
		return (
			<Fragment>
				<SEO title="Verifying" description="verify your email to be able to login" />
				<div>
					<Navbar loginBtn={false} />

					<Container
						style={{
							height: 'calc(100vh - 100px)',
							background: `url(${bgImage}) no-repeat center / cover`,
						}}
					>
						<VerifyEmailContainer>
							<Message style={{ marginBottom: '50px' }}>
								Please verify your email to be able to login.
							</Message>

							<CustomButton id="custom-btn" className="link-btn">
								<Link to="/" className="link-button" style={{ color: '#fff' }}>
									Back to home
								</Link>
							</CustomButton>
						</VerifyEmailContainer>
					</Container>

					<div>
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Fragment>
		)
	}

	// handleAuthentication(({ state }) => navigate(state === "/" ? "/account" : state));
	return (
		<Fragment>
			<SEO title="To Login page" />
			<Container>
				<Message>Logging you in...</Message>
			</Container>
		</Fragment>
	)
}

export const pageQuery = graphql`
	query CallbackQuery {
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
